import { lazy } from "react";

const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    try {
      const component = await componentImport();
      return component;
    } catch (error) {
      throw error;
    }
  });

export const routes = [
  {
    path: "/",
    name: "HomePage",
    Component: lazyWithRetry(() => import("./HomePage")),
    type: "Auth",
  },
  {
    path: "/login",
    name: "LoginPage",
    Component: lazyWithRetry(() => import("./LoginPage")),
  },
  {
    path: "/reports",
    name: "ReportsPage",
    Component: lazyWithRetry(() => import("./ReportsPage")),
  },
  {
    path: "/doctors",
    name: "DoctorsPage",
    Component: lazyWithRetry(() => import("./DoctorsPage")),
    type: "Auth",
  },
  {
    path: "/centers",
    name: "CentersPage",
    Component: lazyWithRetry(() => import("./CentersPage")),
    type: "Auth",
  },
  {
    path: "/doctors/new",
    name: "FormDoctorPage",
    Component: lazyWithRetry(() => import("./FormDoctorPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/doctors/:doctorId/edit",
    name: "FormDoctorPage",
    Component: lazyWithRetry(() => import("./FormDoctorPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/doctors/:id/reports",
    name: "ReportsPage",
    Component: lazyWithRetry(() => import("./ReportsPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/inspectors",
    name: "InspectorsPage",
    Component: lazyWithRetry(() => import("./InspectorsPage")),
    type: "Auth",
  },
  {
    path: "/inspectors/:inspectorId/edit",
    name: "FormInpsectorPage",
    Component: lazyWithRetry(() => import("./FormInspectorPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/inspectors/:id/reports",
    name: "ReportsPage",
    Component: lazyWithRetry(() => import("./ReportsPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/inspectors/new",
    name: "FormInpsectorPage",
    Component: lazyWithRetry(() => import("./FormInspectorPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/centers/new",
    name: "FormCenterPage",
    Component: lazyWithRetry(() => import("./FormCenterPage")),
    edit: false,
    type: "Auth",
  },
  {
    path: "/centers/:centerId/edit",
    name: "FormCenterPage",
    Component: lazyWithRetry(() => import("./FormCenterPage")),
    edit: true,
    type: "Auth",
  },
  {
    path: "/centers/:id/reports",
    name: "ReportsPage",
    Component: lazyWithRetry(() => import("./ReportsPage")),
    edit: false,
    type: "Auth",
  },
];
