import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { routes } from "./Routes/Routes";
import Error404 from "../components/Error404";
import { AuthProvider, useAuth } from "../hooks/useAuth";
import { ToastContainer } from "react-toastify";

const AuthRoute = (props) => {
  const { isAuthUser, type } = props;
  if (type === "guest" && isAuthUser) return <Navigate to="/" />;
  else if (type === "private" && !isAuthUser) return <Navigate to="/login" />;

  return <Route {...props} />;
};

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  if (!user) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return children;
};

const App = () => {
  const routeComponents = routes.map(
    ({ path, Component, edit, type }, index) => {
      if (type == "Auth") {
        return (
          <Route
            exact
            path={path}
            element={
              <ProtectedRoute>
                <React.Suspense fallback={<>...</>}>
                  <Component edit={edit} />
                </React.Suspense>
              </ProtectedRoute>
            }
            key={index}
          />
        );
      } else {
        return (
          <Route
            exact
            path={path}
            element={
              <React.Suspense fallback={<>...</>}>
                <Component edit={edit} />
              </React.Suspense>
            }
            key={index}
          />
        );
      }
    }
  );

  return (
    <Router>
      <AuthProvider userData={window.localStorage.getItem("user")}>
        <Routes>
          {routeComponents}
          <Route render={() => <Error404 />} />
        </Routes>
        <ToastContainer autoClose={3000} position="top-left" />
      </AuthProvider>
    </Router>
  );
};

export default App;
